const menu = document.querySelector('.nav')
const menuBtn = document.querySelector('.hamburger');
const menuToggle = () => {
	menu.classList.toggle('open');
	menuBtn.classList.toggle('open');
}
document.addEventListener("DOMContentLoaded", () => {
	AOS.init({
		startEvent: 'load',
		offset: 50,
		duration: 1000,
		once: true
	})
	menuBtn.addEventListener('click', () => {
		menuToggle()
	});

	const btn = document.querySelector('.scrollTop');

	window.onscroll = function () { scrollFunction() };

	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			btn.style.display = "flex";
		} else {
			btn.style.display = "none";
		}
	}
	const loader = document.querySelector('.pageLoader')
	setTimeout(() => { loader.classList.add('close') }, 1000)
	$('.intro__slider').slick({
		arrows: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		cssEase: 'linear',
		infinite: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		appendDots: '.intro__slider__dots',
		responsive: [
			{
				breakpoint: 992,
				settings: {
					appendDots: '.intro__content__dots',
				}
			}
		],
	});
	if (document.querySelector('.wpcf7')) {
		if (document.querySelector('#productName')) {
			let str = document.querySelector('#productName').textContent;
			document.querySelector('#product').setAttribute('value',str);
		}
	}
});


window.onload = () => {

	$('.shop__slider').slick({
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: false,
		appendArrows: '.shop__slider__arrows',
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.blog__slider').slick({
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		appendArrows: '.blog__slider__arrows',
		responsive: [
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});
};
